import React, { useState, useEffect } from "react";
import Paragraph from "../../ui/Paragragh/Paragraph";
import styles from "./styles.module.css";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { getFaq } from "../../utils/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import right from "../../assets/illustrations/arrow-right.svg";
import trade from "../../assets/illustrations/trade.svg";
import qr from "../../assets/illustrations/qrcode.svg";
import tri from "../../assets/illustrations/tri.svg";
import ss1 from "../../assets/illustrations/sss1.svg";
import small from "../../assets/illustrations/smallsquare.svg";
import middle from "../../assets/illustrations/ss1topmiddle.svg";

const FaqWrapper = () => {
  const [activeId, setActiveId] = useState(null); // Tracks the currently open FAQ
  const [data, setData] = useState([]);

  // Fetch data function
  const fetchData = async () => {
    try {
      const result = await getFaq();
      if (result?.data) {
        setData(result.data);
      } else {
        toast.error("No FAQ data found.");
      }
    } catch (err) {
      toast.error("Error fetching FAQs: " + err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Toggle FAQ visibility
  const toggleFaq = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div>
      <ToastContainer position="top-center" />
      <div className={styles.FaqContainer}>
        <Paragraph
          className={styles.InterHeading}
          text="Frequently Asked Questions"
        />
        <div>
          {data.map((info) => {
            const isOpen = activeId === info.id;

            return (
              <div key={info.id} className={styles.FaqBox}>
                <div
                  onClick={() => toggleFaq(info.id)}
                  role="button"
                  aria-expanded={isOpen}
                  aria-controls={`faq-answer-${info.id}`}
                >
                  <Paragraph
                    className={styles.FaqText}
                    text={
                      info?.attributes?.question || "Question not available"
                    }
                  />
                  <div>
                    <div className={styles.Indicator}>
                      {isOpen ? (
                        <FiMinus fontWeight={600} color="#6932D4" />
                      ) : (
                        <GoPlus fontWeight={600} color="#6932D4" />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  id={`faq-answer-${info.id}`}
                  className={`${styles.FaqAnswer} ${
                    isOpen ? styles.show : styles.hide
                  }`}
                >
                  <Paragraph
                    text={info?.attributes?.answer || "Answer not available"}
                    fontSize={"16px"}
                    fontWeight={"400"}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.Cta}>
        <img src={ss1} alt="" className={styles.ss1} />
        <img src={ss1} alt="" className={styles.ss2} />
        <img src={small} alt="" className={styles.small2} />
        <img src={small} alt="" className={styles.small1} />
        <img src={tri} alt="" className={styles.tri} />
        <img src={middle} alt="" className={styles.middle} />
        <div className={styles.One}>
          <Paragraph
            className={styles.InterText}
            text="Whether you’re a trader, investor, or holder,"
          />
          <br />
          <Paragraph
            className={styles.InterText}
            text="KANTA is designed to meet your needs "
          />
          <br /> <br />
          <br />
          <Paragraph
            className={styles.SecureText}
            text="Secure, Private Decentralized  Exchange app for everyone."
          />
          <div className={styles.Trade}>
            <div className={styles.Button}>
              <Paragraph
                whiteSpace="nowrap"
                fontSize="20px"
                className={styles.SecureText}
                text="Trade on the go"
              />
              <img src={right} alt="" />
            </div>
            <img src={trade} alt="" />
          </div>
        </div>
        <div className={styles.Two}>
          <div>
            <p className={styles.titleText}>Download </p>

            <img src={qr} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqWrapper;
